/*
 * Copyright 2024 Collaterate. All rights reserved.
 */

var dynamic = {
    setClass: function (nodeId, className) {
        $('#' + nodeId)[0].className = className;
    },
    getClass: function (nodeId) {
        return $('#' + nodeId)[0].className;
    },
    removeClass: function (nodeId, className) {
        $('#' + nodeId).removeClass(className);
    },
    addClass: function (nodeId, className) {
        $('#' + nodeId).addClass(className);
    },
    showOverlay: function (divId, zIndex) {
        $('#' + divId).css({
            opacity: 0.3,
            zIndex: zIndex,
            left: '0px',
            top: '0px',
            width: $(document).width() + 'px',
            height: $(document).height() + 'px'
        });
        $('#' + divId).fadeIn();
    },
    hideOverlay: function (divId) {
        $('#' + divId).fadeOut();
    },
    centerDiv: function (divId) {
        var $div = $('#' + divId);
        var prevDisplay = null;
        var width = null;

        if (!$div.length) {
            return;
        }

        prevDisplay = $div.css('display');

        $div.show();

        width = ($(window).width() / 2) - ($div.outerWidth(true) / 2) + 'px';

        $div.css({
            left: width,
            display: prevDisplay
        });
    },
    slideDownDiv: function (divId, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var top = -1 * $('#' + divId).height() - 100;
        $('#' + divId).css({
            top: top + 'px'
        });
        var target = $(window).scrollTop();
        var ydiff = target - top;
        var speed = 400;
        if (options.speed != null) {
            speed = options.speed;
        }
        var onDone = null;
        if (options.ondone != null) {
            onDone = options.ondone;
        }
        $('#' + divId).animate({
            top: ydiff
        }, {
            duration: speed,
            easing: 'easeInOutCirc',
            complete: onDone
        });
    },
    slideUpDiv: function (divId, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var div = $('#' + divId);

        var ydiff = (-1 * div.height() - 100) - div.position().top;
        var speed = 400;
        if (options.speed != null) {
            speed = options.speed;
        }
        var onDone = null;
        if (options.ondone != null) {
            onDone = options.ondone;
        }
        $('#' + divId).animate({
            top: ydiff
        }, {
            duration: speed,
            easing: 'easeInOutCirc',
            complete: onDone
        });
    },
    setDivIdContent: function (divId, contentHtml) {
        var node = $('#' + divId)[0];
        if (node != null) {
            node.innerHTML = contentHtml;
        }
    },
    divHasContent: function (divId) {
        var node = $('#' + divId)[0];
        if (node != null) {
            var content = node.innerHTML;
            return content != null && content != '';
        }
        return false;
    },
    setContentSmooth: function (divId, content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var div = $('#' + divId);
        var contentDivId = divId + 'content';

        // set height to what it is already prevents it from expanding automatically with new content?
        div.css({
            overflow: 'hidden',
            height: div.height()
        });
        var actualContent = '\n<div id="' + contentDivId + '"';
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>\n';
        actualContent += content;
        actualContent += '</div>\n';
        actualContent += '<!-- end ' + contentDivId + ' -->\n';
        this.setDivIdContent(divId, actualContent);
        if (options.beforeShow != null) {
            options.beforeShow();
        }
        var speed = 500;
        if (options.duration != null) {
            speed = options.duration;
        }
        var onDone = null;
        if (options.onDone != null) {
            onDone = options.onDone;
        }
        var contentDiv = $('#' + contentDivId);
        var height = contentDiv.outerHeight(true);
        var pad = content != null && content != '' ? 5 : 0;
        div.animate({
            height: height + pad
        }, {
            duration: speed,
            easing: 'easeInOutCirc',
            complete: onDone
        });
    },
    setContentSmooth2: function (divId, content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var div = $('#' + divId);
        if (options.cssClass != null) {
            div.addClass(options.cssClass);
        }

        // div.css({overflow:'hidden'});
        // NOTE: support for setting style tag has been removed

        var speed = 500;
        if (options.duration != null) {
            speed = options.duration;
        }
        var onDone = null;
        if (options.onDone != null) {
            onDone = options.onDone;
        }
        if (content == null || content == '') {
            div.slideUp(speed, function () {
                dynamic.setDivIdContent(divId, '');
                div.addClass('hideIt');
                if (onDone != null) {
                    onDone();
                }
            });
        } else {
            var setSmoothFunction = function () {
                dynamic.setDivIdContent(divId, content);
                if (options.beforeShow != null) {
                    options.beforeShow();
                }
                div.slideDown(speed, onDone);
            };
            if (this.divHasContent(divId)) {
                div.slideUp(speed, function () {
                    dynamic.setDivIdContent(divId, '');
                    div.addClass('hideIt');
                    setSmoothFunction();
                });
            } else {
                setSmoothFunction();
            }
        }
    },
    divIdExists: function (divId) {
        var arrayOfElementsFound = $('#' + divId);
        return arrayOfElementsFound.length > 0;
    },
    registerConditionalViewTarget: function (masterElementId, triggerElementClass) {
        var $masterElement = $('#' + masterElementId);
        var $triggerElement = $('.' + triggerElementClass);
        dynamic.setConditionalVisibility($masterElement, $triggerElement, false);
        $masterElement.change(function () {
            dynamic.setConditionalVisibility($masterElement, $triggerElement, true);
        });
    },
    setConditionalVisibility: function ($masterElement, $triggerElement, animate) {
        // assume masterElement is a checkbox
        if ($masterElement.attr('checked')) {
            if (animate) {
                $triggerElement.slideDown('fast');
            } else {
                $triggerElement.show();
            }
        } else {
            if (animate) {
                $triggerElement.slideUp();
            } else {
                $triggerElement.hide();
            }
        }
    }
};

function DDOption (id, name) {
    this.id = id;
    this.name = name;
    this.get = function (key) {
        return this[key];
    };
}

var forms = {
    dd: function (name, objectList, selectedObject, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var idProperty = options.idProperty == null ? 'id' : options.idProperty;
        var idFunction = options.idFunction;
        var propertyFunction = function (item) {
            if (item.get) {
                return item.get('name');
            }
            return item.name;
        };
        if (options.nameProperty != null) {
            var nameProperty = options.nameProperty;
            propertyFunction = function (item) {
                if (item.get) {
                    return item.get(nameProperty);
                }
                return item[nameProperty];
            };
        }
        if (options.propertyFunction != null) {
            propertyFunction = options.propertyFunction;
        }
        var excludeItemAndChildren = null;
        if (options.excludeItemAndChildren != null) {
            excludeItemAndChildren = options.excludeItemAndChildren;
        }
        var _objectList = objectList;

        // check for childProperty
        // e.g. {childProperty: 'children'}
        // will include objectList[n].children list items in the dd.
        // each item name will be prepended with a dash for each level,
        // e.g. root
        //      - sub1
        //      -- sub1.1
        var childProperty = null;
        var addItemAndChildrenToList = function (list, level) {
            for (var i = 0, ni = list ? list.length : 0; i < ni; i++) {
                var childObject = list[i];
                if (excludeItemAndChildren == null || excludeItemAndChildren[idProperty] != childObject[idProperty]) {
                    childObject.__ddIndentLevel = level;
                    _objectList[_objectList.length] = childObject;
                    var childList = childObject[childProperty];
                    if (childList && childList.length > 0) {
                        addItemAndChildrenToList(childList, level + 1);
                    }
                }
            }
        };
        var indentedName = function (object) {
            var objectName = propertyFunction(object);
            var dashes = '';
            if (object && object.__ddIndentLevel) {
                for (var i = 0; i < object.__ddIndentLevel; i++) {
                    dashes += '-';
                }
                dashes += ' ';
            }
            return dashes + objectName;
        };
        if (options.childProperty != null) {
            childProperty = options.childProperty;
            _objectList = new Array();
            addItemAndChildrenToList(objectList, 0);
        }

        // generate
        var content = '';
        content += '\n<select name="' + name + '"';
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.onmousedown != null) {
            content += ' onmousedown="' + options.onmousedown + '"';
        }
        if (options.onblur != null) {
            content += ' onblur="' + options.onblur + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        if (options.tabindex != null) {
            content += ' tabindex="' + options.tabindex + '"';
        }
        content += '>\n';
        if (options.showNullOption == null || options.showNullOption == true) {
            var nullOption = 'Select One';
            if (options.nullOption != null) {
                nullOption = options.nullOption;
            }
            content += '<option value="">' + nullOption + '</option>\n';
        }
        for (var i = 0, ni = _objectList ? _objectList.length : 0; i < ni; i++) {
            var object = _objectList[i];
            var selected = '';
            var disabled = '';
            var id = null;
            var selectedId = null;
            if (idProperty == '_INDEX') {
                id = i;
            } else {
                if (idFunction != null) {
                    id = idFunction(object);
                } else if (object.get) {
                    id = object.get(idProperty);
                } else {
                    id = object[idProperty];
                }
            }
            if (selectedObject != null) {
                if (idFunction != null) {
                    selectedId = idFunction(selectedObject);
                } else if (selectedObject.get) {
                    selectedId = selectedObject.get(idProperty);
                } else {
                    selectedId = selectedObject[idProperty];
                }
                if (selectedId == id) {
                    selected = ' selected="selected" ';
                }
            }
            if (options.disableParentOptions && childProperty && object[childProperty].length) {
                disabled = 'disabled';
            }
            content += '<option value="' + strWeb(id) + '" ' + selected + ' ' + disabled + '>';
            content += strWeb(indentedName(object));
            content += '</option>\n';
        }
        content += '</select>\n';
        return content;
    },
    ef: function (name, value, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        content += '<input name="' + name + '" value="';
        if (value != null) {
            content += value;
        }
        content += '"';
        if (options.type == null) {
            content += ' type="text"';
        } else {
            content += ' type="' + options.type + '"';
        }
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        if (options.size != null) {
            content += ' size="' + options.size + '"';
        }
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.onfocus != null) {
            content += ' onfocus="' + options.onfocus + '"';
        }
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.onblur != null) {
            content += ' onblur="' + options.onblur + '"';
        }
        if (options.onkeypress != null) {
            content += ' onkeypress="' + options.onkeypress + '"';
        }
        if (options.onkeyup != null) {
            content += ' onkeyup="' + options.onkeyup + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        if (options.autocomplete != null) {
            content += ' autocomplete="' + options.autocomplete + '"';
        }
        if (options.maxlength != null) {
            content += ' maxlength="' + options.maxlength + '"';
        }
        if (options.tabindex != null) {
            content += ' tabindex="' + options.tabindex + '"';
        }
        if (options.placeholder != null) {
            content += ' placeholder="' + options.placeholder + '"';
        }
        content += '/>';
        return content;
    },
    cb: function (name, text, checked, extraOptions) {
        return forms.cb2(name, text, 'true', checked, extraOptions);
    },
    cb2: function (name, text, value, checked, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        content += '<input name="' + name + '" id="' + name + '" value="' + value + '" type="checkbox"';
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (checked == true) {
            content += ' checked';
        }
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        content += '/>';
        if (hasValue(text)) {
            content += '<label for="' + name + '">';
            content += text;
            content += '</label>';
        }
        return content;
    },
    cb3: function (name, text, checked, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        if (hasValue(text)) {
            content += '<label for="' + name + '">';
        }
        content += '<input name="' + name + '" id="' + name + '" value="true" type="checkbox"';
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (checked == true) {
            content += ' checked';
        }
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        content += '/>';
        if (hasValue(text)) {
            content += text;
            content += '</label>';
        }
        return content;
    },
    rb: function (name, id, value, text, checked, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        content += '<input name="' + name + '" id="' + id + '" value="' + value + '" type="radio"';
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (checked == true) {
            content += ' checked';
        }
        if (options.onchange != null) {
            content += ' onchange="' + options.onchange + '"';
        }
        if (options.onclick == null) {
            content += ' onclick="rbChange(this)"';
        } else {
            content += ' onclick="' + options.onclick + '"';
        }
        content += '/>';
        if (text != null) {
            content += '<label for="' + id + '">';
            content += text;
            content += '</label>';
        }
        return content;
    },
    ta: function (name, value, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        content += '<textarea name="' + name + '"';
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.rows != null) {
            content += ' rows="' + options.rows + '"';
        }
        if (options.onfocus != null) {
            content += ' onfocus="' + options.onfocus + '"';
        }
        if (options.onblur != null) {
            content += ' onblur="' + options.onblur + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        if (options.tabindex != null) {
            content += ' tabindex="' + options.tabindex + '"';
        }
        content += '>';
        content += value;
        content += '</textarea>\n';
        return content;
    },
    button: function (value, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var content = '';
        content += '<button';
        var type = 'button';
        if (options.type != null) {
            type = options.type;
        }
        content += ' type="' + type + '"';
        if (options.name != null) {
            content += ' id="' + options.name + '"';
            content += ' name="' + options.name + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.onclick != null) {
            content += ' onclick="' + options.onclick + '"';
        }
        if (options.disabled != null && options.disabled === true) {
            content += ' disabled';
        }
        if (options.tabindex != null) {
            content += ' tabindex="' + options.tabindex + '"';
        }
        if (options.style) {
            content += ' style="' + options.style + '"';
        }
        content += '>';
        content += value;
        content += '</button>';
        return content;
    },
    phone: function (selector, overrideOptions) {
        var defaultOptions = {
            nationalMode: true,
            initialCountry: 'auto',
            preferredCountries: ['us', 'ca'],
            geoIpLookup: function (callback) {
                $.get('https://ipinfo.io', function () {}, 'jsonp')
                    .always(function (resp) {
                        var countryCode = resp && resp.country ? resp.country : '';

                        if (options.hiddenInput) {
                            $('[name="' + oldName + '"]').val($input.val());
                        }

                        callback(countryCode);
                    });
            },
            utilsScript: '/web3/build/base/js/intltel/utils.js',
            useHidden: false
        };

        var options = $.extend({}, defaultOptions, overrideOptions);
        var $input = $(selector);
        var oldName, newName;

        if (!$input.length) {
            return;
        }

        if (options.useHidden) {
            oldName = $input.prop('name');
            newName = oldName + '_ui';
            $input.prop('name', newName);
            options.hiddenInput = oldName;
            $input.on('change countrychange', function (e) {
                $('[name="' + oldName + '"]').val($input.intlTelInput('getNumber'));
            });
        }

        $input.intlTelInput(options)
            .attr('type', 'tel')
            .attr('autocomplete', 'nope');

        if (!$input.intlTelInput('getSelectedCountryData').name) {
            $input.intlTelInput('setCountry', 'us');
        }

        return $input;
    },
    destroyPhone: function (selector) {
        var $input = $(selector);

        if (!$input.length) {
            return;
        }

        $input.intlTelInput('destroy');
    }
};

var cframes = {
    iframe: function (id, handlerURL, width, height, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        var frameborder = 0;
        if (options.frameborder != null) {
            frameborder = options.frameborder;
        }
        var scrolling = 'auto';
        if (options.scrolling != null) {
            scrolling = options.scrolling;
        }

        // generate
        var content = '';
        content += '<iframe id="' + id + '" src="' + handlerURL + '" width="' + width + '" height="' + height + '" frameborder="' + frameborder + '" scrolling="' + scrolling + '" ';
        if (options.onload != null) {
            content += ' onload="' + options.onload + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        content += '>\n';
        content += 'ERROR: Your browser does not support iframes.\n';
        content += '</iframe>\n';
        return content;
    },
    getDoc: function (iframeID) {
        var frame = $('#' + iframeID)[0];

        // TODO check for cross browser
        var window = frame.contentWindow;
        return window;
    },
    print: function (iframeID) {
        var frame = this.getDoc(iframeID);
        frame.focus();
        frame.print();
    }
};

var tags = {
    a: function (text, url, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var content = '';
        content += '<a href="' + url + '"';
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.name != null) {
            content += ' name="' + options.name + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.target != null) {
            content += ' target="' + options.target + '"';
        }
        if (options.title != null) {
            content += ' title="' + options.title + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        if (options.onclick != null) {
            content += ' onclick="' + options.onclick + '"';
        }
        if (options.tabindex != null) {
            content += ' tabindex="' + options.tabindex + '"';
        }
        if (options.other != null) {
            content += ' ' + options.other + '';
        }
        content += '>';
        if (text == null || text == '') {
            content += '&nbsp;';
        } else {
            content += text;
        }
        content += '</a>\n';
        return content;
    },
    div: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<div';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        if (options.onclick != null) {
            actualContent += ' onclick="' + options.onclick + '"';
        }
        if (options.title != null) {
            actualContent += ' title="' + options.title + '"';
        }
        if (options.ngInclude != null) {
            actualContent += ' ng-include="\'' + options.ngInclude + '\'"';
        }
        if (options.ngApp != null) {
            actualContent += ' ng-app="' + options.ngApp + '"';
        }
        if (options.ngInit != null) {
            actualContent += ' ng-init="' + options.ngInit + '"';
        }
        if (options.other != null) {
            actualContent += ' ' + options.other + '';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</div>\n';
        return actualContent;
    },
    label: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<label ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        if (options.forId != null) {
            actualContent += ' for="' + options.forId + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</label>\n';
        return actualContent;
    },
    span: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<span ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</span>';
        return actualContent;
    },
    strong: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<strong ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</strong>';
        return actualContent;
    },
    p: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<p ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</p>\n';
        return actualContent;
    },
    pre: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<pre ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</pre>\n';
        return actualContent;
    },
    h1: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<h1 ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</h1>\n';
        return actualContent;
    },
    h2: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<h2 ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</h2>\n';
        return actualContent;
    },
    h3: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<h3 ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</h3>\n';
        return actualContent;
    },
    h4: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var actualContent = '';
        actualContent += '<h4 ';
        if (options.id != null) {
            actualContent += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            actualContent += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            actualContent += ' style="' + options.style + '"';
        }
        actualContent += '>';
        actualContent += content;
        actualContent += '</h4>\n';
        return actualContent;
    },
    img: function (url, width, height, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }

        // generate
        var content = '';
        content += '<img src="' + url + '"';
        if (width != null) {
            content += ' width="' + width + '"';
        }
        if (height != null) {
            content += ' height="' + height + '"';
        }
        if (options.border == null) {
            options.border = 0;
        }
        for (var i in options) {
            content += ' ' + i + '="' + options[i] + '"';
        }
        content += '/>\n';
        return content;
    }
};

function JDUL (options) {
    return new JDList('ul', options);
}
function JDOL (options) {
    return new JDList('ol', options);
}

function JDList (tag, options) {
    this.tag = tag;
    this.options = options;
    if (this.options == null) {
        this.options = {};
    }
    this.items = new Array();

    // functions
    this.add = function (content, options) {
        var each = new JDLI(content, options);
        this.items[this.items.length] = each;
    };
    this.generate = function () {
        var options = this.options;
        var content = '';
        content += '<' + this.tag + ' ';
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        content += '>\n';
        for (var i = 0; i < this.items.length; i++) {
            var item = this.items[i];
            content += item.generate();
        }
        content += '</' + this.tag + '>\n';
        return content;
    };
}

function JDLI (content, options) {
    this.content = content;
    this.options = options;
    if (this.options == null) {
        this.options = {};
    }

    // functions
    this.generate = function () {
        var content = '';
        var options = this.options;
        content += '<li';
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        content += '>\n';
        content += this.content;
        content += '</li>\n';
        return content;
    };
}

function JDTable (extraOptions) {
    this.options = extraOptions;
    if (this.options == null) {
        this.options = {};
    }
    this.rows = new Array();

    // functions
    this.generate = function () {
        var options = this.options;
        var content = '';
        content += '<table ';
        if (options.cellspacing != null) {
            content += ' cellspacing="' + options.cellspacing + '"';
        }
        if (options.cellpadding != null) {
            content += ' cellpadding="' + options.cellpadding + '"';
        }
        if (options.border != null) {
            content += ' border="' + options.border + '"';
        }
        if (options.width != null) {
            content += ' width="' + options.width + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        content += '>\n';
        if (options.caption != null) {
            content += '<caption>' + options.caption + '</caption>\n';
        }

        if (this.headerRow != null) {
            content += '<thead>' + this.headerRow.generate() + '</thead>\n<tbody>\n';
        }

        for (var i = 0; i < this.rows.length; i++) {
            var row = this.rows[i];
            content += row.generate();
        }

        if (this.headerRow != null) {
            content += '</tbody>\n';
        }

        content += '</table>\n';
        return content;
    };
    this.addRow = function (cells, extraOptions) {
        var row = new JDRow();
        row.cells = cells;
        if (extraOptions != null) {
            row.options = extraOptions;

            if (extraOptions.headerRow === true) {
                this.headerRow = row;

                // returning so header row is not added to rows.
                return;
            }
        }
        this.rows[this.rows.length] = row;
    };
}

function JDRow () {
    this.cells = new Array();
    this.options = {};

    // functions
    this.generate = function () {
        var content = '';
        var options = this.options;
        var tag = 'td';
        if (options.tag != null) {
            tag = options.tag;
        }
        content += '<tr';
        if (options.id != null) {
            content += ' id="' + options.id + '"';
        }
        if (options.cssClass != null) {
            content += ' class="' + options.cssClass + '"';
        }
        if (options.style != null) {
            content += ' style="' + options.style + '"';
        }
        content += '>\n';
        for (var i = 0; i < this.cells.length; i++) {
            var cell = this.cells[i];
            if (isObject(cell)) {
                var cellTag = tag;
                if (cell.tag != null) {
                    cellTag = cell.tag;
                }
                content += '<' + cellTag;
                if (cell.cssClass != null) {
                    content += ' class="' + cell.cssClass + '"';
                }
                if (cell.style != null) {
                    content += ' style="' + cell.style + '"';
                }
                if (cell.scope != null) {
                    content += ' scope="' + cell.scope + '"';
                }
                if (cell.colspan != null) {
                    content += ' colspan="' + cell.colspan + '"';
                }
                if (cell.nowrap != null && cell.nowrap == true) {
                    content += ' nowrap="nowrap"';
                }
                if (cell.dataElements) {
                    for (var di = 0; di < cell.dataElements.length; di++) {
                        var dataElement = cell.dataElements[di];
                        content += ' ' + dataElement.attributeName + '="' + dataElement.attributeValue + '"';
                    }
                }
                content += '>';
                content += cell.text;
                content += '</' + cellTag + '>\n';
            } else {
                content += '<' + tag + '>';
                content += cell;
                content += '</' + tag + '>\n';
            }
        }
        content += '</tr>\n';
        return content;
    };
}

function isObject (anything) {
    if (anything == null) {
        return false;
    }
    return typeof anything == 'object';
}

function strWeb (aString) {
    if (!isString(aString)) {
        return aString == null ? '' : aString;
    }

    var answer = aString;
    answer = answer.replace(/&/g, '&amp;');
    answer = answer.replace(/</g, '&lt;');
    answer = answer.replace(/>/g, '&gt;');
    answer = answer.replace(/\'/g, '&apos;');
    answer = answer.replace(/"/g, '&quot;');

    return answer;
}
function strSize (aString, maxSize) {
    if (!isString(aString)) {
        return aString;
    }
    if (aString.length > (maxSize - 3)) {
        return aString.substring(0, maxSize - 3) + '...';
    }
    return aString;
}
function strWS (aString, maxSize) {
    return strWeb(strSize(aString, maxSize));
}
function strUnweb (aString) {
    if (!isString(aString)) {
        return aString == null ? '' : aString;
    }

    var answer = aString;
    answer = answer.replace(/&amp;/g, '&');
    answer = answer.replace(/&lt;/g, '<');
    answer = answer.replace(/&gt;/g, '>');
    answer = answer.replace(/&apos;/g, '\'');
    answer = answer.replace(/&quot;/g, '"');
    answer = answer.replace(/&#(12[7-9]|1[3-5][0-9]);/g, '');

    if (aString !== answer) {
        return strUnweb(answer);
    }

    return answer;
}
function strRemoveAngleBrackets (aString) {
    if (aString == null) {
        return '';
    }
    if (!isString(aString)) {
        return aString;
    }
    var answer = '';
    for (var i = 0; i < aString.length; i++) {
        var d = aString.charAt(i);
        if (d == '<') {
            answer += '';
        } else if (d == '>') {
            answer += '';
        } else {
            answer += d;
        }
    }
    return answer;
}

/*
 * help me
 */
function strBR (aString) {
    if (!isString(aString)) {
        return aString;
    }
    var answer = '';
    for (var i = 0; i < aString.length; i++) {
        var d = aString.charAt(i);
        if (d == '\n') {
            answer += '<br />';
        } else {
            answer += d;
        }
    }
    return answer;
}
function strWB (aString) {
    var step1 = strWeb(aString);
    var step2 = strBR(step1);
    return step2;
}

// so it can be in a javascript string
function strJS (aString) {
    if (!isString(aString)) {
        return aString;
    }
    var answer = '';
    for (var i = 0; i < aString.length; i++) {
        var d = aString.charAt(i);
        if (d == '\n') {
            answer += ' ';
        } else if (d == '"') {
            answer += ' ';
        } else if (d == "'") {
            answer += ' ';
        } else if (d == '<') {
            answer += ' ';
        } else if (d == '>') {
            answer += ' ';
        } else {
            answer += d;
        }
    }
    return answer;
}
function strPrice (aNumber) {
    if (aNumber == null) {
        return '';
    }
    return '$' + aNumber.toFixed(2);
}
function numPrice (aString) {
    if (!isString(aString)) {
        throw new TypeError('Expected string but got ' + typeof aString);
    }

    return Number(parseFloat(aString.replace(/[^0-9.]/gi, '')).toFixed(2));
}
function strPriceNoSign (aNumber) {
    if (aNumber == null) {
        return '';
    }
    return aNumber.toFixed(2);
}
function strPriceNoSignZeroForNull (aNumber) {
    if (aNumber == null) {
        return '0.00';
    }
    return aNumber.toFixed(2);
}
function strPriceWithGrouping (aNumber) {
    return '$' + strPriceNoSignWithGrouping(aNumber);
}
function strPriceNoSignWithGrouping (aNumber) {
    // http://stackoverflow.com/a/2901298
    return ('' + strPriceNoSign(aNumber)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function formatIntWithGrouping (aNumber) {
    // http://stackoverflow.com/a/2901298
    return ('' + parseInt('' + aNumber, 10)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function strDate (aDate) {
    if (aDate == null || aDate == '') {
        return '';
    }
    return formatDate(aDate, 'MM/dd/yyyy');
}
function strFloatPrecision (numberString, precision) {
    if (numberString == null) {
        return '';
    }
    var aFloat = parseFloat(numberString);
    if (precision == null) {
        return numberString;
    }
    return aFloat.toPrecision(precision);
}
function strInt (numberString) {
    if (numberString == null) {
        return '';
    }
    var anInt = parseInt(numberString);
    return anInt;
}
function strDateTime (aDate) {
    if (aDate == null || aDate == '') {
        return '';
    }
    return formatDate(aDate, 'MM/dd/yyyy hh:mm:ss a');
}
function strShortTime (aDate) {
    if (aDate == null || aDate == '') {
        return '';
    }
    return formatDate(aDate, 'hh:mm a');
}
function strTime (aDate) {
    if (aDate == null || aDate == '') {
        return '';
    }
    return formatDate(aDate, 'hh:mm:ss a');
}
function strAddress (add1, add2, city, state, zip, country) {
    var content = '';
    content += hasValue(add1) ? add1 + '\n' : '';
    content += hasValue(add2) ? add2 + '\n' : '';
    content += hasValue(city) ? city : '';
    content += hasValue(state) ? ', ' + state : '';
    content += (hasValue(zip) ? ' ' + zip : '') + '\n';
    content += hasValue(country) ? country : '';
    return content;
}
function strAddressLinear (add1, add2, city, state, zip, country) {
    var content = '';
    content += hasValue(add1) ? add1 : '';
    content += hasValue(content) && hasValue(add2) ? ', ' : '';
    content += hasValue(add2) ? add2 : '';
    content += hasValue(content) && hasValue(city) ? ', ' : '';
    content += hasValue(city) ? city : '';
    content += hasValue(content) && hasValue(state) ? ', ' : '';
    content += hasValue(state) ? state : '';
    content += hasValue(content) && hasValue(zip) ? ' ' : '';
    content += hasValue(zip) ? zip : '';
    content += hasValue(content) && hasValue(country) ? ' ' : '';
    content += hasValue(country) ? country : '';
    return content;
}
function stringTrim (s) {
    if (s == null) {
        return s;
    }
    while (s.substring(0, 1) == ' ') {
        s = s.substring(1, s.length);
    }
    while (s.substring(s.length - 1, s.length) == ' ') {
        s = s.substring(0, s.length - 1);
    }
    return s;
}
function hasValue (aString) {
    if (aString == null || stringTrim(aString) == '') {
        return false;
    }
    return true;
}
function isString (anyValue) {
    return typeof anyValue == 'string';
}
function lineCount (aString, charsPerLine) {
    if (!isString(aString)) {
        return 0;
    }
    var working = aString;
    var count = 0;
    var index = working.indexOf('\n');
    while (index != -1) {
        count++;
        if ((index + 1) < working.length) {
            working = working.substr(index + 1);
            index = working.indexOf('\n');
        } else {
            break;
        }
    }
    count = count + Math.floor(aString.length / charsPerLine);
    if (count == 0) {
        return 1;
    }
    return count;
}

function moneyToFloat (str) {
    if (str === null || str === undefined || str.constructor !== String) {
        return;
    }

    var regex = /[^-\d\.]/g; // non-numeric characters except for periods (decimal points) and dash (negative values)

    return parseFloat(str.replace(regex, ''));
}

var pageLog = null;
function logDebug (methodName, message) {
    if (DEBUG && pageLog != null) {
        pageLog.logDebug(methodName, message);
    }
}
